@use "../../styles/partials/global" as *;

.header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: to-rem(15) to-rem(30);
  background-color: $white;
  @include tablet {
    display: flex;
    flex-direction: row;
  }

  &__logo {
    display: flex;
    align-items: center;
    font-size: to-rem(24);
  }

  &__icon {
    margin-right: to-rem(5);
  }

  &__title {
    font-size: to-rem(35);
    font-weight: bold;
    background: $gradient-color;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    @include nunito-bold;
  }

  &__nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include tablet {
      display: flex;
      flex-direction: row;
    }
  }

  &__link {
    margin: 0 to-rem(10);
    font-size: to-rem(18);
    @include nunito-semibold;
    color: $dark-blue;
    padding: to-rem(10);
    text-decoration: none;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: $grey;
      border-radius: to-rem(4);
    }
  }

  &__link--active {
    color: $dark-blue-header;
    font-weight: bold;
  }

  &__link--logout {
    background: none;
    border: none;
    cursor: pointer;
    color: $dark-blue-header;
    font-size: to-rem(18);
    padding: to-rem(10);
    transition: background-color 0.3s ease;

    &:hover {
      background-color: $grey;
      border-radius: to-rem(4);
    }
  }
}
