@use "../../styles/partials/global" as *;

.users-page {
  padding: to-rem(40);

  h1 {
    margin-bottom: $padding-lg;
    @include h1-style;
    color: $dark-blue;
    margin-top: to-rem(62);
  }

  &__user-list {
    margin-top: $padding-lg;
    display: flex;
    flex-direction: column;
  }

  &__user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: to-rem(10);
    border-bottom: to-rem(1) solid $neutral-gray;

    &:hover {
      background-color: $grey;
    }
  }

  &__email {
    font-size: to-rem(18);
    color: $black;
  }

  &__delete-icon {
    width: $padding-lg;
    height: $padding-lg;
    cursor: pointer;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.7;
    }
  }
}
