@use "../../styles/partials/global" as *;
.visuals-page {
  padding: to-rem(62);
  max-width: to-rem(1200);
  margin: 0 auto;
  text-align: center;

  &__content {
    display: flex;
    flex-direction: column;

    @include tablet {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      gap: $padding-lg;
    }
  }

  &__item {
    background-color: $white;
    border-radius: to-rem(8);
    box-shadow: 0 to-rem(4) to-rem(8) rgba(0, 0, 0, 0.1);
    overflow: hidden;
    flex: 0 0 30%;
    margin-bottom: $padding-lg;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: to-rem(10);
    @include tablet {
      max-width: 30%;
    }
  }

  &__image {
    width: 100%;
    height: auto;
    max-width: 100%;
    object-fit: cover;
  }

  &__text {
    padding: to-rem(10);
  }

  h2 {
    font-size: 1.5rem;
    margin: to-rem(10) 0;
  }

  p {
    font-size: 1rem;
    margin: to-rem(5) 0;
    margin-bottom: to-rem(12);
  }

  em {
    color: $gray-words;
  }
  &__button {
    margin-top: to-rem(30);
  }
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }

  .modal__content {
    background-color: $white;
    padding: $padding-lg;
    border-radius: to-rem(5);
    text-align: center;
  }

  .modal__close-button {
    padding: to-rem(10) to-rem(20);
    background-color: $grey;
    border-radius: $padding-lg;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: to-rem(16);
    font-weight: 500;
    color: $black;
    margin-top: to-rem(10);
    &:hover {
      background-color: $gray-hover;
    }
  }
}
