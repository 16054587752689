@use "../../styles/partials/global" as *;

.button {
  @include button-style;

  &:hover {
    @include button-style-hover;
  }

  &--loading {
    font-size: to-rem(16);
    @include nunito-regular;
    background-color: $dark-blue;
    cursor: not-allowed;
  }

  .loading-spinner {
    border: to-rem(2) solid rgba(255, 255, 255, 0.2);
    border-top: to-rem(2) solid $white;
    border-radius: 50%;
    width: to-rem(14);
    height: to-rem(14);
    animation: spin 1s linear infinite;
    display: inline-block;
    margin-right: to-rem(8);
    vertical-align: middle;
    margin-bottom: to-rem(2);
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
