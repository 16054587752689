@use "../../styles/partials/global" as *;

.dashboard {
  padding: $padding-lg;
  text-align: center;

  &__title {
    @include h1-style;
    color: $dark-blue;
    margin-top: to-rem(42);
  }

  &__decks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__deck-card {
    position: relative;
    background-color: $white;
    border: 1px solid $light-gray-border;
    border-radius: to-rem(10);
    width: to-rem(150);
    margin: to-rem(10);
    padding: $padding-lg;
    cursor: pointer;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    &-name {
      @include h2-style;
      color: $dark-blue;
    }

    &-count {
      font-size: to-rem(14);
      color: $gray-medium;
    }
  }

  &__delete-icon {
    position: absolute;
    top: to-rem(10);
    right: to-rem(10);
    width: to-rem(15);
    height: to-rem(15);
    cursor: pointer;
    filter: invert(130%) sepia(4%) saturate(158%) hue-rotate(164deg)
      brightness(89%) contrast(85%);
    transition: opacity 0.2s ease-in-out;

    &:hover {
      filter: invert(130%) sepia(4%) saturate(158%) hue-rotate(164deg)
        brightness(75%) contrast(85%);
    }
  }

  &__add-deck-button {
    margin-top: to-rem(15);
  }

  &__modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__modal-content {
    background-color: $white;
    padding: to-rem(20);
    border-radius: to-rem(10);
    max-width: to-rem(400);
    text-align: center;
  }
  &__button {
    width: to-rem(120);
    padding: to-rem(10) to-rem(20);
    background-color: $grey;
    border-radius: to-rem(20);
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: to-rem(16);
    font-weight: 500;
    color: $black;
    margin: 0 to-rem(20);
    &:hover {
      background-color: $gray-hover;
    }
  }
}
