@use "../../styles/partials/global" as *;

.results-page {
  padding: $padding-lg;
  max-width: to-rem(1200);
  margin: 0 to-rem(20);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  @include desktop {
    margin: 0 to-rem(130);
  }

  &__title {
    @include h1-style;
    color: $dark-blue;
    margin-top: to-rem(62);
  }
  &__text {
    @include p1-style;
    color: $dark-blue;
    margin-top: to-rem(0);
    margin-bottom: to-rem(30);
  }

  &__table {
    border-collapse: collapse;
    margin-bottom: $padding-lg;
    thead {
      @include mobile {
        display: none;
      }
    }
    tbody tr {
      @include mobile {
        display: flex;
        flex-direction: column;
      }
    }

    th,
    td {
      padding: to-rem(12) to-rem(15);
      text-align: left;
      border-bottom: to-rem(1) solid $neutral-gray;
    }

    th {
      background-color: $white;
    }

    td {
      background-color: $white;
      &--checkbox {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &__word {
    @include mobile {
      font-weight: bold;
      font-size: to-rem(18);
    }
  }
  &__translation {
    @include mobile {
      font-style: italic;
    }
  }

  &__button {
    margin-top: to-rem(30);
  }
}
