@use '../../styles/partials/global' as *;

.selection-page {
  text-align: center;
  padding: $padding-lg;

  &__title {
    @include h1-style;
    color: $dark-blue;
    margin-top: to-rem(62);
    margin-bottom: to-rem(40);
  }

  &__cloud {
    display: flex;
    flex-wrap: wrap;
    gap: to-rem(10);
    justify-content: center;
    margin: to-rem(20) to-rem(40);
  }

  &__tag {
    padding: to-rem(10) to-rem(20);
    background-color: $grey;
    border-radius: $padding-lg;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: to-rem(16);
    font-weight: 500;

    &--selected {
      background-color: $violet;
      color: $white;
    }

    &:hover {
      background-color: $gray-hover;
    }
  }

  &__save-button {
    margin-top: to-rem(30);
  }
}
