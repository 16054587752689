@use "../../styles/partials/global" as *;

.home {
  padding-top: to-rem(10);
  display: flex;
  flex-direction: column;
  align-items: center;

  &__container {
    margin-top: to-rem(170);
    display: flex;
  }
  &__icon {
    margin-right: to-rem(5);
    font-size: to-rem(38);
    display: flex;
    align-items: center;
  }
  &__logo {
    @include logo-style;
    width: 100%;
    display: flex;
    margin-bottom: to-rem(0);
    justify-content: center;
    background: $gradient-color;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    margin-top: 0;

    @include tablet {
      background: linear-gradient(181deg, #470972, #6fbff0, #ff69b4);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  }

  &__description {
    margin-top: to-rem(4);
    padding: 0 to-rem(50);
    @include h2-style;
    color: $dark-blue;
    text-align: center;

    @include tablet {
      width: 50%;
    }
  }

  &__button {
    display: flex;
    margin-top: $padding-lg;
  }
}
