@use "../../styles/partials/global" as *;

.deck-addition {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    @include h1-style;
    color: $dark-blue;
    margin-top: to-rem(62);
    margin-bottom: $padding-lg;
  }
  &__language-title {
    @include p1-style;
    color: $dark-blue;
    margin-top: to-rem(0);
    margin-left: 1.25rem;
    margin-bottom: 0.5rem;
    @include mobile {
      margin-top: 1rem;
    }
  }

  &__input {
    padding: to-rem(10) to-rem(20);
    border: 1px solid $gray-input;
    border-radius: to-rem(5);
    transition: background-color 0.3s ease;
    margin-bottom: $padding-lg;
  }

  &__error-message {
    color: $red;
    margin-bottom: $padding-lg;
  }

  &__language-selectors {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include tablet {
      display: flex;
      flex-direction: row;
    }
  }

  &__language-selector {
    padding: to-rem(10) to-rem(20);
    border: 1px solid $gray-input;
    border-radius: to-rem(5);
    cursor: pointer;
    transition: background-color 0.3s ease;
    background-color: $white;
    margin: 0 to-rem(20);
    width: to-rem(202);
    @include mobile {
      margin-top: to-rem(10);
      margin-left: $padding-lg;
      margin-right: $padding-lg;
    }

    &:hover {
      background-color: $white;
    }
  }

  &__modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__modal-content {
    background-color: $white;
    padding: to-rem(20);
    border-radius: to-rem(10);
    max-height: 80%;
    overflow-y: auto;
  }

  &__language-list {
    display: flex;
    flex-direction: column;
  }

  &__language-item {
    padding: to-rem(10);
    border-bottom: 1px solid $neutral-gray;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: $gray-soft-light;
    }
  }

  &__button {
    margin-top: to-rem(30);
  }
}
